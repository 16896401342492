.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Map container */
#map {
  position: relative;
  width: 100vw;
  height: 100vh;
}



/* Sliding panel */
.panel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 50%; /* Set a maximum height for the panel */
  background-color: white;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(100%); /* Hidden off-screen */
  transition: transform 0.3s ease-in-out;
  overflow-y: auto; /* Allow scrolling for the entire panel */
}

/* Make the panel visible */
.panel.visible {
  transform: translateY(0); /* Slide up into view */
}

/* Panel content */
.bakery-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Horizontally scrollable images */
.horizontal-scroll {
  display: flex;
  gap: 10px;
  overflow-x: auto; /* Allow horizontal scrolling for images */
  padding: 10px 0;
  margin-bottom: 20px; /* Space between images and pie details */
  height: 200px; /* Adjust height for the image slider */
}

.bakery-image,
.pie-image {
  height: 180px; /* Adjust the image height for uniformity */
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bakery-name {
  font-size: 2rem;
  font-weight: bold;
}

.bakery-address {
  font-size: 1rem;
  color: #555;
}

/* Pies list */
.pies-list {
  margin-top: 20px;
  /* Remove independent scrolling styles */
  /* Remove max-height to let it flow naturally */
}

.pie-item {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pie-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.pie-price {
  font-size: 1rem;
  color: #555;
}

.pie-comments {
  font-size: 0.9rem;
  color: #777;
}


.map-icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.chart-container {
  display: flex;
  flex-direction: column;
  gap: 0px; /* Adjust this value to control spacing */
}

.chart-wrapper {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}