/* Container styles */
.tomato-container {
  background-color: rgb(160, 40, 40);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center; /* Align items to the left */
  padding: 0 0.5rem; /* Add some padding for spacing from the edges */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Vertical container */
.tomato-header {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  margin-top: 0px; /* Space from the top */
  align-items: flex-start; /* Align items to the left */
}

.tomato-main-menu-button {
  background: url('/public/images/SIDE2.png') no-repeat center center;
  background-size: contain; /* Ensure the image scales proportionally */
  border: none;
  width: 25vw; /* Set width to 30% of the viewport width */
  aspect-ratio: 2 / 1; /* Maintain aspect ratio */
  cursor: pointer;
  margin: 0; /* No extra margins */
  position: relative;
  top: 0; /* Align it with the top of its container */
  padding-bottom: 10px;
}
/* Text inside button (optional if just the car image) */
.tomato-main-menu-text {
  color: black;
  font-size: 0.6rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
}


.tomato-h1 {
  font-size: clamp(1.5rem, 5vw, 5rem); /* Dynamically adjust font size */
  color: white;
  text-transform: uppercase;
  line-height: 1.2; /* Adjust line spacing */
  margin: 0; /* Remove default margin */
  text-align: center; /* Center the heading text */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Ensure text does not overflow the container */
  text-overflow: ellipsis; /* Add "..." if the text overflows */
  max-width: 100%; /* Ensure the text stays within the width of its container */
  padding-top: 20px; /* Adds padding inside the button */
}

.tomato-body {
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Canvas and halo */
.tomato-canvas-container {
  position: relative;
  width: 80%; /* Set width relative to the 3D model */
  height: 200px; /* Match height to keep the proportions */
}

.tomato-halo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
  width: 300px; /* Halo size */
  height: 300px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) 0%, /* Bright center */
    rgba(255, 255, 255, 0) 70% /* Smooth fade to transparency */
  );
  border-radius: 50%; /* Makes it circular */
  z-index: 0; /* Ensures it stays behind the canvas */
  pointer-events: none; /* Prevents interference with interaction */
}

/* Title and subtitle */
.tomato-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  text-align: center; /* Centers the text */
  margin: 1rem;
}

.tomato-subtitle {
  position: relative; /* Explicitly position the subtitle */
}

.tomato-letter {
  display: inline-block;
  font-size: inherit; /* Inherit the size from h1 */
  color: yellow;
  animation: tomato-slide-in 0.1s ease-in-out; /* Smooth sliding effect */
}

/* Animation for letter transitions */
@keyframes tomato-slide-in {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Input styles */
.tomato-input-container {
  display: flex; /* Makes the elements align horizontally */
  gap: 1rem; /* Adds spacing between input fields and button */
  margin-top: 1rem; /* Adds spacing above the input-container */
}

.tomato-input-box {
  padding: 0.1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  width: 120px; /* Fixed width for input fields */
}

.tomato-input-box:focus {
  border-color: #333; /* Change border color when focused */
}

.tomato-submit-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: yellow;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.tomato-submit-button:hover {
  background-color: #ffc107; /* Slightly darker yellow on hover */
}

/* Banner styles */
.tomato-banner-container {
  width: 100%; /* Full width of the page */
  overflow: hidden; /* Hide content that overflows the container */
  white-space: nowrap; /* Prevent text wrapping */
  color: white; /* Text color */
  padding: 0.5rem 0; /* Add some vertical padding */
  position: relative; /* Ensure relative positioning for marquee content */
}

.tomato-marquee {
  display: flex; /* Align the two spans side by side */
  animation: tomato-scroll 10s linear infinite; /* Smooth infinite scrolling */
}

.tomato-marquee span {
  display: inline-block;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  font-size: 1rem;
  font-weight: bold;
}

@keyframes tomato-scroll {
  from {
    transform: translateX(100%); /* Start at the initial position */
  }
  to {
    transform: translateX(-100%); /* Move entirely out of view */
  }
}

/* Cropping container */
.tomato-canvas-crop {
  width: 100%;
  height: 300px; /* Define how much of the canvas you want to show */
  overflow: hidden; /* Crop any overflowing content */
  position: relative; /* Required for the cropping effect */
}

.tomato-canvas-container {
  width: 100%;
  height: 400px; /* Original height of the canvas */
  position: relative;
}
