.van-container {
  position: absolute;
  width: 80%; /* Van width as a percentage of screen width */
  aspect-ratio: 3 / 2; /* Maintain van aspect ratio */
  top: 30%; /* Start from the middle of the viewport */
  animation: move 5s linear infinite; /* Move across screen */
}

.van-body {
  position: absolute;
  width: 100%; /* Take full width of the container */
  height: auto; /* Maintain aspect ratio */
}

.wheel {
  position: absolute;
  width: 11%; /* Width relative to the van's container */
  aspect-ratio: 1 / 1; /* Ensure wheels remain circular */
}

.wheel-left {
  bottom: 40%; /* Align bottom of the wheel to the van */
  left: 19.5%; /* Position wheel horizontally relative to the van */
  transform: translate(-50%, 0); /* Center horizontally */
}

.wheel-right {
  bottom: 40%; /* Align bottom of the wheel to the van */
  right: 19.5%; /* Position wheel horizontally relative to the van */
  transform: translate(50%, 0); /* Center horizontally */
}

.spinning {
  animation: spin 1s linear infinite; /* Rotate wheels */
}

.light {
  position: absolute;
  width: 100%; /* Match van's width */
  height: auto; /* Maintain aspect ratio */
  top: 0; /* Align lights with the top of the van */
}

.red-light {
  z-index: 1; /* Ensure the red light is on top */
}

.orange-light {
  z-index: 1; /* Ensure the orange lights are on top */
}

.blinking {
  animation: blink 1s ease-in-out infinite;
}

/* Animations */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes move {
  from {
    transform: translateX(-150%);
  }
  to {
    transform: translateX(150%);
  }
}