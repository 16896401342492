.shape {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.2;
  pointer-events: none;
}

.circle {
  border: 3px solid black;
  border-radius: 50%;
}

.solid-circle {
  background-color: black;
  border-radius: 50%;
}

.square {
  border: 3px solid black;
}

.solid-square {
  background-color: black;
}

.score-number {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  z-index: 1;
}