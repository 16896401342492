.progress-bar-container {
  margin: 10px 0;
}

.progress-title {
  font-size: 0.9rem;
  color: #555;
}

.progress-bar {
  height: 10px;
  position: relative; /* Required for overlay positioning */
  background-color: #e0e0e0; /* Base background for unfilled */
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
}

/* The full gradient background */
.progress-bar-fill {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, red, yellow, green);
  z-index: 1; /* Place it beneath the overlay */
}

/* Overlay for the unfilled portion */
.progress-bar-overlay {
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #e0e0e0; /* Matches the base unfilled background */
  z-index: 2; /* Overlay on top of the gradient */
  pointer-events: none; /* Make it non-interactive */
}